<template>
  <div v-if="_g_IsUserLogged" class="primary-container">
    <div id="main-container" class="mdc-layout-grid__inner" v-if="_g_UserFeatures.permissions.portfolio_management">
      <LoadingForum v-if="isLoading" ref="loader" @loaded="finishLoading" :message="loadingMessage" class="full-screen-loader" />
      <div class="mdc-layout-grid__cell--span-12" :class="{ 'loading-hidden': isLoading, 'loading-visible': !isLoading }">
        <div class="iframe-container">
          <iframe name="portfolio" id="portfolio-id" style="overflow:hidden" width="100%" height="90%" frameBorder="0" @load="handleIframeLoad"></iframe>
        </div>
      </div>
    </div>
    <div v-else class="not-allowed-portfolio-container">
      <p class="not-allowed-portfolio-title"> SIN ACCESO </p>
      <p class="not-allowed-portfolio"> Su plan actual no tiene acceso a esta herramienta. <a href="/app/store" class="link-to-store"> Pulse aquí para cambiar de plan. </a> </p>
    </div>
  </div>
  <div v-else>
    <div id="main-container" class="mdc-layout-grid__cell--span-12 mdc-layout-grid__cell--span-1-phone anonymous-portoflio" style="text-align: center;">
      <div class="container-image mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--align-middle">
        <img src="https://s3.eu-west-1.amazonaws.com/assets.eurekers.com/anonymous/herramientas/main/gestion_cartera.jpg">
        <div class="text-center">
          <h2>Gestión de cartera</h2>
          <p>Controla tus inversiones en todo momento dentro de un mismo espacio de trabajo. Comprueba rápidamente el estado
            de tu/s cuenta/s, mira tus posiciones, revisa tus stops, anota tus observaciones y planifica tus próximos
            movimientos en función de tu metodología de inversión.</p>
        </div>
        <div class="button-container">
            <button class="login-button" @click="_redirectToLogin()">LOGIN</button>
        </div> 
      </div>
    </div>
  </div>
  <!--<div class="mdc-layout-grid__inner" id="portfolio">
    <div class="mdc-layout-grid__cell--span-12">
      <div class="mdc-data-table">
        <table class="mdc-data-table__table" aria-label="Dessert calories">
          <thead>
            <tr class="mdc-data-table__header-row" >
              <th id="table-header"
                class="mdc-data-table__header-cell mdc-data-table__header-cell--with-sort"
                role="columnheader"
                scope="col"
                aria-sort="none"
                data-column-id="dessert"
              >
                <div class="mdc-data-table__header-cell-wrapper">
                  <div class="mdc-data-table__header-cell-label">Nombre</div>
                  <button
                    class="mdc-icon-button material-icons mdc-data-table__sort-icon-button"
                    aria-label="Sort by dessert"
                    aria-describedby="dessert-status-label"
                  >arrow_upward</button>
                  <div
                    class="mdc-data-table__sort-status-label"
                    aria-hidden="true"
                    id="dessert-status-label"
                  ></div>
                </div>
              </th>
              <th id="table-header"
                class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric mdc-data-table__header-cell--with-sort mdc-data-table__header-cell--sorted"
                role="columnheader"
                scope="col"
                aria-sort="ascending"
                data-column-id="carbs"
              >
                <div class="mdc-data-table__header-cell-wrapper">
                  <button
                    class="mdc-icon-button material-icons mdc-data-table__sort-icon-button"
                    aria-label="Sort by carbs"
                    aria-describedby="carbs-status-label"
                  >arrow_upward</button>
                  <div class="mdc-data-table__header-cell-label">Capital inicial</div>
                  <div
                    class="mdc-data-table__sort-status-label"
                    aria-hidden="true"
                    id="carbs-status-label"
                  ></div>
                </div>
              </th>
              <th id="table-header"
                class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric mdc-data-table__header-cell--with-sort"
                role="columnheader"
                scope="col"
                aria-sort="none"
                data-column-id="protein"
              >
                <div class="mdc-data-table__header-cell-wrapper">
                  <button
                    class="mdc-icon-button material-icons mdc-data-table__sort-icon-button"
                    aria-label="Sort by protein"
                    aria-describedby="protein-status-label"
                  >arrow_upward</button>
                  <div class="mdc-data-table__header-cell-label">Capital real</div>
                  <div
                    class="mdc-data-table__sort-status-label"
                    aria-hidden="true"
                    id="protein-status-label"
                  ></div>
                </div>
              </th>
              <th id="table-header"
                class="mdc-data-table__header-cell"
                role="columnheader"
                scope="col"
                data-column-id="comments"
              >Divisa</th>

              <th id="table-header"
                class="mdc-data-table__header-cell"
                role="columnheader"
                scope="col"
                data-column-id="comments"
              >Fecha de Creación</th>

              <th id="table-header"
                class="mdc-data-table__header-cell"
                role="columnheader"
                scope="col"
                data-column-id="comments"
              >Opciones</th>
            </tr>
          </thead>
          <tbody class="mdc-data-table__content table-body" >
            <tr 
              class="mdc-data-table__row"
              v-for="portfolio in userPortfolios"
              :key="portfolio.idc"
            >
              <td  class="mdc-data-table__cell">{{ portfolio.name }}</td>
              <td
                class="mdc-data-table__cell mdc-data-table__cell--numeric"
              >{{ portfolio.initial_capital }}</td>
              <td
                class="mdc-data-table__cell mdc-data-table__cell--numeric"
              >{{ portfolio.real_capital }}</td>
              <td class="mdc-data-table__cell">{{ portfolio.currency }}</td>
              <td class="mdc-data-table__cell">{{ portfolio.data }}</td>
              <td class="mdc-data-table__cell">
                <span title="Actualizar cartera" class="material-icons portfolio" @click="_openPortfolioUpdate(portfolio.name,portfolio.risk,portfolio.risk_strategy,portfolio.idc)">edit</span>
                <span title="Modificar balance diario" class="material-icons portfolio" @click="_openPortfolioBalance(portfolio.idc)">menu_book</span>
                <span v-if="portfolio.alert_subscription == false" title="Suscribirse a recibir alarmas por email" class="material-symbols-rounded campana" @click="_emailActivate(portfolio.idc)">notifications</span>
                <span v-else title="Desactivar alarmas por email" class="material-symbols-rounded no-activate" @click="_desactivateAlert(portfolio.idc)">notifications</span>
                <span title="Clone portfolio" class="material-icons portfolio" @click="_clonePortfolio(portfolio.idc)">content_copy</span>
                <span title="Reset portfolio" class="material-icons portfolio" @click="_resetPortfolio(portfolio.real_capital,portfolio.currency,portfolio.data,portfolio.risk,portfolio.risk_strategy,portfolio.idc)">refresh</span>
                <span title="Borrar cartera" class="material-icons portfolio" @click="_deletePortfolio(portfolio.idc)">delete_outline</span>
              </td>
            </tr>
          </tbody>
        </table>        
      </div>
      <div class="options-forms">
        <div class="options">
          <Button id="portfolio" class="create-portfolio" @_emit_method_button="_openPortfolioCreator" :button_name="button_name" :button_key="button_key_comments" />
        </div>      

        <div v-if="portfolio_creator">
          <PortfolioCreator 
          :portfolio_creator="portfolio_creator"
          @portfolio_creator="portfolio_creator=$event"/>
        </div>
        <div v-if="portfolio_update">
          <PortfolioUpdate
          :portfolio_update_name="portfolio_update_name"
          :portfolio_update_risk="portfolio_update_risk"
          :portfolio_update_risk_strategy="portfolio_update_risk_strategy"
          :portfolio_id="portfolio_id"
          :portfolio_update="portfolio_update"
          @portfolio_update="portfolio_update=$event"/>
        </div>
        <div v-if="portfolio_delete">
          <PortfolioDelete
          :portfolio_delete="portfolio_delete"
          :portfolio_id="portfolio_id"
          @portfolio_delete="portfolio_delete=$event"/>
        </div>
        <div v-if="portfolio_clone">
          <PortfolioClone
          :portfolio_clone="portfolio_clone"
          :portfolio_id="portfolio_id"
          @portfolio_clone="portfolio_clone=$event"/>
        </div>        
        <div v-if="portfolio_reset">
          <PortfolioReset
          :portfolio_reset_capital="portfolio_reset_capital"
          :portfolio_reset_divisa="portfolio_reset_divisa"
          :portfolio_reset_fecha="portfolio_reset_fecha"
          :portfolio_reset_risk="portfolio_reset_risk"
          :portfolio_reset_strategy_risk="portfolio_reset_strategy_risk"
          :portfolio_reset="portfolio_reset"
          :portfolio_id="portfolio_id"
          @portfolio_reset="portfolio_reset=$event"/>
        </div>
        <div v-if="portfolio_email">
          <PortfolioEmail
          :portfolio_email="portfolio_email"
          :portfolio_id="portfolio_id"
          @portfolio_email="portfolio_email=$event"/>
        </div>
      </div>   
    </div>     
  </div>-->
</template>



<script>
// import { MDCDataTable } from '@material/data-table';
// import PortfolioCreator from '@/views/Portfolio_creator.vue';
// import Button from "@/components/helpers/Button";
// import APICaller from "@/components/codeComponents/APICaller.js";
// import PortfolioUpdate from '@/views/Portfolio_update.vue';
// import PortfolioDelete from '@/views/Portfolio_popup_delete.vue';
// import PortfolioEmail from '@/views/Portfolio_popup_email.vue';
// import PortfolioClone from '@/views/Portfolio_popup_clone.vue';
// import PortfolioReset from '@/views/Portfolio_reset.vue';
// import { mapMutations, mapGetters } from "vuex";
import { mapGetters } from "vuex";
import LoadingForum from '@/components/stockchart/components/LoadingForum.vue';

export default {
  //mixins: [APICaller],
  name: 'Portfolio',
  metaInfo() {
    return {
      title: 'Gestión de cartera Eurekers',
      meta: [
        { name: 'description', content: "Controla tus inversiones en todo momento dentro de un mismo espacio de trabajo. Comprueba rápidamente el estado de tu/s cuenta/s, mira tus posiciones, revisa tus stops, anota tus observaciones y planifica tus próximos movimientos en función de tu metodología de inversión." }
      ]
    }
  },
  components: {
    // Button,
    // PortfolioCreator,
    // PortfolioUpdate,
    // PortfolioDelete,
    // PortfolioClone,
    // PortfolioReset,
    // PortfolioEmail
    LoadingForum
  },
  data: () => ({
    // portfolios: [],
    // button_name: "Crear cartera",
    // button_key_comments: "button_comments",    
    // button_name2: "Preferencias",
    // button_key_comments2: "button_commentss",

    // createPortfolioMenu: false,
    // portfolio_creator: false,

    // portfolio_update: false,
    // portfolio_update_name: "",
    // portfolio_update_risk: 0,
    // portfolio_update_risk_strategy:"",
    // portfolio_id:"",

    // portfolio_delete: false,
    // portfolio_clone: false,
    // portfolio_email:false,

    // portfolio_reset:false,
    // portfolio_reset_capital:"",
    // portfolio_reset_divisa:"",
    // portfolio_reset_fecha:"",
    // portfolio_reset_risk:"",
    // portolio_reset_strategy_risk:"",
    // changes_portfolio: false,
    isLoading: true,
    loadingMessage: "Cargando gestión de cartera..."
  }),
  mounted() {
    this._chargeIframe();
    // const idataTable = new MDCDataTable(document.querySelector('.mdc-data-table'));
    // const adapter = (idataTable.getDefaultFoundation()).adapter;
    // adapter.addClass('eurekers-table-header');
    // this._getPortfolios();

  },
  computed: {
    // ...mapGetters(['_g_ChangesInPortfolio']),
    // userPortfolios() {
    //   return this.portfolios;
    // },

    ...mapGetters(["_g_IsUserLogged", "_g_User", "_g_UserFeatures"])

  },
  watch: {
    // _g_ChangesInPortfolio: function(){
    //   this._getPortfolios();
    // }
  },
  methods: {
    // ...mapMutations(['setPortfolioList']),

    // _openPortfolioBalance: function(value){
    //   window.location.href = "/app/portfolio/balance/" + value;
    // },
    // _getPortfolios() {
    //   let success = response =>{
    //     this.portfolios = response.data;
    //     this.setPortfolioList(this.portfolios);
    //   }

    //   let failure = ()=>{this.$vueOnToast.pop('error',"Error recovering portfolio list.");}

    //   let url = '/api/v1/portfolio/list'

    //   this._getAPICall(url, success, failure)
    // },

    // _resetPortfolio(capital,divisa,fecha,risk,strategy_risk,id){
    //   this.portfolio_id = id;
    //   this.portfolio_reset_capital=parseInt(capital);
    //   this.portfolio_reset_divisa=divisa;
    //   this.portfolio_reset_fecha=fecha;
    //   this.portfolio_reset_risk= parseInt(risk);
    //   this.portfolio_reset_strategy_risk = strategy_risk;
    //   this.portfolio_reset = !this.portfolio_reset;
    // },

    // _openPortfolioCreator: function(){
    //   this.portfolio_creator = !this.portfolio_creator;
    // },
    // _openPortfolioUpdate: function(name,risk,risk_strategy,id){
    //   this.portfolio_id = id;
    //   this.portfolio_update_name = name;
    //   this.portfolio_update_risk = parseInt(risk);
    //   this.portfolio_update_risk_strategy = risk_strategy;
    //   this.portfolio_update = !this.portfolio_update;
    // },
    // ...mapMutations(['setChangesInPortfolio']),
    // _createPortfolio: function(){
    //   var params = new URLSearchParams();            
    //   params.append('finder', JSON.stringify(""));
    //   let success = (response) => {
    //     this.setChangesInPortfolio();             
    //   };
    //   let failure = () => {
    //   };

    //   let url = "api/v1/portfolio/create";

    //   this._postAPICall(url, params, success, failure);
    // },
    // _activatePreferences: function(){
    //   this.createPreferencesMenu = !this.createPreferencesMenu;
    // },
    // _desactivateAlert: function(idc){      
    //   let success = () => {
    //     this.$vueOnToast.pop('success', "Alarmas", "Desactivadas con éxito");
    //    };
    //   let failure = () => {
    //     this.$vueOnToast.pop('error', "Error", "Desactivando alertas, por favor inténtelo de nuevo o contacte con nosotros" + ' - Error code 003.3');
    //   };

    //   let url = "/api/v1/portfolio/"+idc+"/unsubscribe-to-alerts";

    //   this._getAPICall(url, success, failure);

    // },
    // _deletePortfolio: function(id){
    //   this.portfolio_id = id;
    //   this.portfolio_delete = !this.portfolio_delete;
    // },
    // _clonePortfolio: function(id){
    //   this.portfolio_id = id;
    //   this.portfolio_clone = !this.portfolio_clone;
    // },
    // _emailActivate: function(id){
    //   this.portfolio_id = id;
    //   this.portfolio_email = !this.portfolio_email;
    // },
    _chargeIframe: function () {
      var portfolio = document.getElementById("portfolio-id");
      if(this.$route.params.param2){
        portfolio.src = process.env.VUE_APP_API + '/carteras/' + this.$route.params.param1 + "/general#" + this.$route.params.param2
      }else{
        portfolio.src = process.env.VUE_APP_API + '/carteras'
      }
    },
    _redirectToLogin() {
        window.location.href = "/user/login"; // Reemplaza la URL con la que desees redirigir
    },
    finishLoading() {
      setTimeout(() => {
        this.isLoading = false;
      }, 500);
    },
    
    handleIframeLoad() {
      const iframe = document.getElementById("portfolio-id");
      
      if (iframe && iframe.src && iframe.src.trim() !== '') {
        if (this.$refs.loader) {
          this.$refs.loader.finishLoading();
        }
      }
    },
  },

}
</script>

<style lang="scss" scoped>
@use "@material/checkbox"; // Required only for data table with row selection.
@use "@material/icon-button"; // Required only for data table with column sorting.
@use "@material/data-table/data-table";

@include checkbox.core-styles;
@include icon-button.core-styles;
@include data-table.core-styles;
@include data-table.theme-baseline;

html {
  overflow-y: scroll;
}

#portfolio {
  margin: auto;
  width: 98%;
  margin-top: 20px;
}

#table-header {
  background-color: white;
}

.primary-container{
  height: 100%;
}

.options {
  display: flex;
  width: 400px;

}

.create-portfolio {
  margin-right: 10px;
  font-size: 13px !important;
  margin-left: 0px !important;
  background-color: #5bb75b;
}

.preferences {
  margin-left: 10px;
  font-size: 13px !important;
}

.input-form {
  width: 200px;
  text-align: center;
}

a {
  text-decoration: none;
}

.material-icons.portfolio {
  color: #00add8;
  font-size: 19px;
  cursor: pointer;
  margin-right: 4px;
}

.material-symbols-rounded {
  color: #00add8;
  font-size: 19px;
  cursor: pointer;
  margin-right: 4px;
  font-variation-settings:
    'FILL' 1,
}

.material-symbols-rounded.no-activate {
  font-variation-settings:
    'FILL' 0,
}

@media (max-height: 500px) {
  #portfolio {
    margin-top: 60px;
    margin-left: 12px;
  }
}

#main-container {
  height: 100%;
  width: 100%;
  overflow: hidden !important;
}

.iframe-container {
  height: 100% !important;
}

header {
  display: none !important;
}

.container-image {
  /* Aplica un margen automático para centrar el div horizontalmente */
  margin: 0 auto;
  /* Ajusta el ancho máximo del contenedor */
  max-width: 800px;
  max-height: auto;

}



.container-image img {
  max-width: 500px;
  max-height: 800px;
  margin: 20px;
  border-radius: 5px;
}

@media screen and (min-width: 768px) {
  .container-image img {
    width: 500px;
  }
}

.text-center {
  text-align: center;
  margin: 20px;
}

.container-image p {
  text-align: justify;
}

.unauthorized {
    display: flex;
    align-items: center;
    height: 100%;
    text-align: center;
    justify-content: center;
    font-size: 20px;
}

.login-button{
    color: white;
    background-color: #00add8;
    border-radius: 20px;
    cursor: pointer;
    padding: 10px;
    text-decoration: none;
    border: none;
    outline: none;
    width: 150px;
    margin-top: 20px;
    font-size: 14px;
  }
  .login-button:hover{
    background-color: #00bdec;
  }

  .not-allowed-portfolio-container{
    text-align: center;
    margin-top: 50px;
  }
  
  .link-to-store{
    color: #35add8 !important;
  }

  .not-allowed-portfolio-title{
    color: #FF9B00;
    font-weight: bold;
  }

  .anonymous-portoflio {
    opacity: 100% !important;
  }

  /* Initially hide the main content but keep it rendered */
  .mdc-layout-grid__cell--span-12 {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }

  /* When loading finishes, fade it in */
  .loading-visible {
    opacity: 1;
  }

  /* Keep it hidden during loading */
  .loading-hidden {
    opacity: 0;
  }

  /* Make the loading indicator cover the full screen */
  .full-screen-loader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background-color: var(--color-background, #fff);
    display: flex;
    justify-content: center;
    align-items: center;
  }

</style>
