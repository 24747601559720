<template>
    <transition name="fade">
      <div v-if="visible" class="loading-container">
        <p class="loading-text typo-headline-6">{{ message }}</p>
        <div class="progress-bar-container">
          <div class="progress-bar" :style="{ width: progress + '%' }"></div>
        </div>
      </div>
    </transition>
  </template>
  
  <script>
  export default {
    data() {
      return {
        progress: 0,
        loaded: false,
        visible: true, // For fade-out animation
        interval: null,
      };
    },
    props: {
      message: {
        type: String,
        default: "Cargando..."
      }
    },
    mounted() {
      this.startFakeProgress();
    },
    methods: {
      startFakeProgress() {
        this.interval = setInterval(() => {
          if (this.loaded) {
            this.progress = Math.max(this.progress + 10, 100);
          } else {
            if (this.progress < 80) {
              this.progress += Math.random() * 5; // Increase slowly
            }
          }
        }, 500);
      },
      finishLoading() {
        this.loaded = true;
        let fastInterval = setInterval(() => {
          this.progress += 10;
          if (this.progress >= 100) {
            clearInterval(fastInterval);
            clearInterval(this.interval);
            this.visible = false;
            this.$emit("loaded");
          }
        });
      }
    }
  };
  </script>
  
  <style scoped>
  .loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    font-family: Arial, sans-serif;
    background: var(--color-background);
  }
  
  .loading-text {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: var(--color-text-secondary);
  }
  
  .progress-bar-container {
    width: 20%;
    height: 6px;
    background: var(--color-surface);
    border: 1px solid var(--color-outline);
    overflow: hidden;
  }
  
  .progress-bar {
    height: 100%;
    background: var(--color-highlight);
    width: 0%;
    transition: width 0.4s ease-out;
  }
  
  /* Fade transition */
  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s ease;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
  </style>
  